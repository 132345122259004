var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "partition-form van-common-detail-button" },
    [
      _c(
        "van-form",
        {
          ref: "appForm",
          attrs: {
            "input-align": _vm.constants.inputAlign,
            "label-width": "110px"
          }
        },
        [
          _c("div", { staticClass: "area-title" }, [
            _c("p", { staticClass: "title" }, [_vm._v(" 货物信息 ")])
          ]),
          _c("van-cell", {
            attrs: {
              title: "提货申请编号：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.applyNo)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "仓库名称：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.warehouseName)
            }
          }),
          _c("van-cell", { attrs: { title: "提货类型：", value: "拆柜提货" } }),
          _c("van-field", {
            attrs: {
              label: "申请提货日期：",
              placeholder: "请选择日期",
              required: "",
              rules: [{ required: true }]
            },
            on: {
              click: function($event) {
                _vm.showApplyDate = true
              }
            },
            model: {
              value: _vm.appForm.applyDate,
              callback: function($$v) {
                _vm.$set(_vm.appForm, "applyDate", $$v)
              },
              expression: "appForm.applyDate"
            }
          }),
          _c(
            "van-popup",
            {
              attrs: { round: "", position: "bottom" },
              model: {
                value: _vm.showApplyDate,
                callback: function($$v) {
                  _vm.showApplyDate = $$v
                },
                expression: "showApplyDate"
              }
            },
            [
              _c("van-datetime-picker", {
                attrs: { type: "date" },
                on: {
                  confirm: function(date) {
                    return _vm.confirmDate(date)
                  },
                  cancel: function($event) {
                    _vm.showApplyDate = false
                  }
                },
                model: {
                  value: _vm.currentDate,
                  callback: function($$v) {
                    _vm.currentDate = $$v
                  },
                  expression: "currentDate"
                }
              })
            ],
            1
          ),
          _c("van-cell", {
            attrs: {
              title: "柜号：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.cabinetNo)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "货物名称：",
              value: _vm.utils.isEffectiveCommon(_vm.appForm.cargoName)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "提货方式：",
              value: _vm.appForm.cargoType === 1 ? "按件提货" : "按重量提货"
            }
          }),
          _vm._l(_vm.appForm.goodsList, function(item, index) {
            return _c("van-cell-group", { key: item.id }, [
              _c("div", { staticClass: "van-common-left" }, [
                _c("span", [_vm._v(_vm._s(index + 1))])
              ]),
              _c(
                "div",
                { staticClass: "van-common-right" },
                [
                  _c("van-cell", {
                    attrs: {
                      title: "品名：",
                      value: _vm.utils.isEffectiveCommon(item.productName)
                    }
                  }),
                  _vm.appForm.cargoType === 1
                    ? _c("van-cell", {
                        attrs: {
                          title: "认定单价（元）：",
                          value: _vm.utils.isEffectiveCommon(
                            item.packageUnitPrice
                          )
                        }
                      })
                    : _c("van-cell", {
                        attrs: {
                          title: "单价（原币）：",
                          value: _vm.utils.isEffectiveCommon(item.unitPrice)
                        }
                      }),
                  _vm.appForm.cargoType === 1
                    ? _c("van-cell", {
                        attrs: {
                          title: "账面件数：",
                          value: _vm.utils.isEffectiveCommon(item.pieceNumber)
                        }
                      })
                    : _c("van-cell", {
                        attrs: {
                          title: "账面重量（KG）：",
                          value: _vm.utils.isEffectiveCommon(item.weight)
                        }
                      }),
                  _vm.appForm.cargoType === 1
                    ? _c("van-cell", {
                        attrs: {
                          title: "可提件数：",
                          value: _vm.utils.isEffectiveCommon(
                            item.extractableNumber
                          )
                        }
                      })
                    : _c("van-cell", {
                        attrs: {
                          title: "可提重量（KG）：",
                          value: _vm.utils.isEffectiveCommon(
                            item.extractableWeight
                          )
                        }
                      }),
                  _c("van-field", {
                    attrs: {
                      label:
                        _vm.appForm.cargoType === 1
                          ? "申请提货件数"
                          : "申请提货重量",
                      placeholder: "请输入",
                      required: "",
                      rules: [
                        { required: true },
                        {
                          pattern: _vm.constants.rulesNumber,
                          message: _vm.constants.rulesToolkitsNumber
                        }
                      ]
                    },
                    on: {
                      blur: function($event) {
                        return _vm.applyNumberBlur(
                          item.applyNumber,
                          item.pieceNumber
                        )
                      }
                    },
                    model: {
                      value: item.applyNumber,
                      callback: function($$v) {
                        _vm.$set(item, "applyNumber", $$v)
                      },
                      expression: "item.applyNumber"
                    }
                  })
                ],
                1
              )
            ])
          }),
          _c("van-cell", {
            attrs: {
              title: _vm.appForm.cargoType === 1 ? "提货总件数" : "提货总重量",
              value: _vm.utils.isEffectiveCommon(_vm.totalNumber)
            }
          }),
          _c("van-cell", {
            attrs: {
              title: "提货总金额（元）：",
              value: _vm.utils.isEffectiveCommon(_vm.totalMoney)
            }
          }),
          _c("div", { staticClass: "area-title" }, [
            _c("p", { staticClass: "title" }, [_vm._v(" 装车信息 ")])
          ]),
          _c("van-field", {
            attrs: {
              label: "司机姓名：",
              placeholder: "请输入司机姓名",
              maxlength: 20
            },
            model: {
              value: _vm.appForm.vehiclesInfoVo.contacts,
              callback: function($$v) {
                _vm.$set(_vm.appForm.vehiclesInfoVo, "contacts", $$v)
              },
              expression: "appForm.vehiclesInfoVo.contacts"
            }
          }),
          _c("van-field", {
            attrs: {
              label: "电话：",
              placeholder: "请输入电话",
              maxlength: 20
            },
            model: {
              value: _vm.appForm.vehiclesInfoVo.phone,
              callback: function($$v) {
                _vm.$set(_vm.appForm.vehiclesInfoVo, "phone", $$v)
              },
              expression: "appForm.vehiclesInfoVo.phone"
            }
          }),
          _c("van-field", {
            attrs: {
              label: "身份证号：",
              placeholder: "请输入身份证号",
              maxlength: 20
            },
            model: {
              value: _vm.appForm.vehiclesInfoVo.certificates,
              callback: function($$v) {
                _vm.$set(_vm.appForm.vehiclesInfoVo, "certificates", $$v)
              },
              expression: "appForm.vehiclesInfoVo.certificates"
            }
          }),
          _c("van-field", {
            attrs: {
              label: "车牌号：",
              placeholder: "请输入车牌号",
              maxlength: 20
            },
            model: {
              value: _vm.appForm.vehiclesInfoVo.numberPlates,
              callback: function($$v) {
                _vm.$set(_vm.appForm.vehiclesInfoVo, "numberPlates", $$v)
              },
              expression: "appForm.vehiclesInfoVo.numberPlates"
            }
          }),
          _c("van-field", {
            attrs: {
              label: "备注：",
              placeholder: "请输入备注",
              maxlength: 200
            },
            model: {
              value: _vm.appForm.vehiclesInfoVo.remarks,
              callback: function($$v) {
                _vm.$set(_vm.appForm.vehiclesInfoVo, "remarks", $$v)
              },
              expression: "appForm.vehiclesInfoVo.remarks"
            }
          }),
          _c(
            "div",
            { staticClass: "button-fixed" },
            [
              _c(
                "van-button",
                {
                  staticClass: "button-fixed-margin",
                  attrs: { type: "info", block: "" },
                  on: { click: _vm.submit }
                },
                [_vm._v(" 提交 ")]
              ),
              _c(
                "van-button",
                {
                  attrs: { type: "info", block: "" },
                  on: {
                    click: function($event) {
                      return _vm.$router.back()
                    }
                  }
                },
                [_vm._v(" 返回 ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }