<template>
  <div class="partition-form van-common-detail-button">
    <van-form ref="appForm" :input-align="constants.inputAlign" label-width="110px">
      <div class="area-title">
        <p class="title">
          货物信息
        </p>
      </div>
      <van-cell title="提货申请编号：" :value="utils.isEffectiveCommon(appForm.applyNo)" />
      <van-cell title="仓库名称：" :value="utils.isEffectiveCommon(appForm.warehouseName)" />
      <van-cell title="提货类型：" value="拆柜提货" />
      <van-field v-model="appForm.applyDate" label="申请提货日期：" placeholder="请选择日期" required :rules="[{ required: true }]" @click="showApplyDate = true" />
      <van-popup v-model="showApplyDate" round position="bottom">
        <van-datetime-picker v-model="currentDate" type="date" @confirm="date => confirmDate(date)" @cancel="showApplyDate = false" />
      </van-popup>
      <van-cell title="柜号：" :value="utils.isEffectiveCommon(appForm.cabinetNo)" />
      <van-cell title="货物名称：" :value="utils.isEffectiveCommon(appForm.cargoName)" />
      <van-cell title="提货方式：" :value="appForm.cargoType===1? '按件提货':'按重量提货'" />
      <van-cell-group v-for="(item,index) in appForm.goodsList" :key="item.id">
        <div class="van-common-left">
          <span>{{ index+1 }}</span>
        </div>
        <div class="van-common-right">
          <van-cell title="品名：" :value="utils.isEffectiveCommon(item.productName)" />
          <van-cell v-if="appForm.cargoType===1" title="认定单价（元）：" :value="utils.isEffectiveCommon(item.packageUnitPrice)" />
          <van-cell v-else title="单价（原币）：" :value="utils.isEffectiveCommon(item.unitPrice)" />
          <van-cell v-if="appForm.cargoType===1" title="账面件数：" :value="utils.isEffectiveCommon(item.pieceNumber)" />
          <van-cell v-else title="账面重量（KG）：" :value="utils.isEffectiveCommon(item.weight)" />
          <van-cell v-if="appForm.cargoType===1" title="可提件数：" :value="utils.isEffectiveCommon(item.extractableNumber)" />
          <van-cell v-else title="可提重量（KG）：" :value="utils.isEffectiveCommon(item.extractableWeight)" />
          <van-field v-model="item.applyNumber" :label="appForm.cargoType===1? '申请提货件数':'申请提货重量'" placeholder="请输入" required :rules="[{ required: true }, { pattern: constants.rulesNumber, message: constants.rulesToolkitsNumber }]" @blur="applyNumberBlur(item.applyNumber, item.pieceNumber)" />
        </div>
      </van-cell-group>
      <van-cell :title="appForm.cargoType===1? '提货总件数':'提货总重量'" :value="utils.isEffectiveCommon(totalNumber)" />
      <van-cell title="提货总金额（元）：" :value="utils.isEffectiveCommon(totalMoney)" />
      <div class="area-title">
        <p class="title">
          装车信息
        </p>
      </div>
      <van-field v-model="appForm.vehiclesInfoVo.contacts" label="司机姓名：" placeholder="请输入司机姓名" :maxlength="20" />
      <van-field v-model="appForm.vehiclesInfoVo.phone" label="电话：" placeholder="请输入电话" :maxlength="20" />
      <van-field v-model="appForm.vehiclesInfoVo.certificates" label="身份证号：" placeholder="请输入身份证号" :maxlength="20" />
      <van-field v-model="appForm.vehiclesInfoVo.numberPlates" label="车牌号：" placeholder="请输入车牌号" :maxlength="20" />
      <van-field v-model="appForm.vehiclesInfoVo.remarks" label="备注：" placeholder="请输入备注" :maxlength="200" />
      <div class="button-fixed">
        <van-button type="info" class="button-fixed-margin" block @click="submit">
          提交
        </van-button>
        <van-button type="info" block @click="$router.back()">
          返回
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import moment from 'moment'
import { List, PullRefresh, Cell, CellGroup, Search, Button, Row, Col, Form, Field, Popup, Picker, Uploader, SwipeCell, Toast, Calendar, Checkbox, CheckboxGroup, RadioGroup, Radio, DatetimePicker } from 'vant'

export default {
  components: {
    [DatetimePicker.name]: DatetimePicker,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [CheckboxGroup.name]: CheckboxGroup,
    [Checkbox.name]: Checkbox,
    [Calendar.name]: Calendar,
    [Toast.name]: Toast,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Search.name]: Search,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [Form.name]: Form,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Uploader.name]: Uploader,
    [SwipeCell.name]: SwipeCell
  },
  data () {
    return {
      stockContainerId: this.$route.query.stockContainerId,
      showApplyDate: false,
      currentDate: new Date(),
      accountForm: {},
      showGoods: false,
      detailList: [],
      totalNumber: '',
      totalMoney: '',
      appForm: {
        applyNo: '',
        applyDate: '',
        cabinetNo: '',
        cargoName: '',
        money: '',
        extractableNumber: '',
        warehouseName: '',
        extractableWeight: '',
        actualPieceNumber: '',
        actualWeight: '',
        vehiclesInfoVo: {},
        containerInfoVo: {},
        goodsList: []
      },
      finishedText: '没有更多了',
      page: 1,
      tableList: [],
      loading: {
        detail: false
      },
      finished: false,
      refreshing: false,
      loadingSubmit: false
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    // 获取详情
    getDetail () {
      this.api.delivery.packOutCabinetInfoDetail(this.stockContainerId).then(result => {
        const resData = result.data.value
        this.appForm = this._.assign(this.appForm, resData)
      }).catch((e) => {
      })
    },
    confirmDate (val) {
      this.appForm.applyDate = `${moment(val).format('YYYY-MM-DD')}`
      this.showApplyDate = false
    },
    applyNumberBlur (val, pieceNumber) {
      const date = this._.toNumber(val)
      if (!this._.isNumber(date) || this._.isNaN(date)) {
        Toast.fail('请输入数字')
        return false
      }
      if (Number(date) >= Number(pieceNumber)) {
        Toast.fail('申请提货件数不能大于账面件数')
        return false
      }
      this.totalNumber = 0
      this.totalMoney = 0
      this.appForm.goodsList.forEach(item => {
        const date = this._.toNumber(item.applyNumber)
        if (this._.isNumber(date) && !this._.isNaN(date)) {
          this.totalNumber += date
          if (this.appForm.cargoType === 1) {
            this.totalMoney += this.NP.times(date, this._.toNumber(item.packageUnitPrice))
          } else {
            this.totalMoney += this.NP.times(date, this._.toNumber(item.unitPrice))
          }
        }
      })
    },
    // 提交
    submit () {
      this.$refs.appForm.validate().then(() => {
        const data = this._.cloneDeep(this.appForm)
        data.outType = 2
        data.outMethod = this.appForm.cargoType
        data.applyDate = `${data.applyDate} 00:00:00`
        data.containerInfoVo = this._.cloneDeep(data)
        sessionStorage.setItem('deliveryForm', JSON.stringify(data || {}))
        this.$router.push({
          path: '/delivery/apply/openDetail',
          query: {}
        })
      })
    }
  }
}
</script>

<style>
</style>
